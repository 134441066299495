import $ from "jquery";
import app from "./src/App";
import { HeaderAccount, HeaderSearch, HeaderStickyScrollUp } from "./components/header";
import "css/main.scss";

HeaderSearch();
HeaderAccount();
HeaderStickyScrollUp();

$("#mc-embedded-subscribe").one("click", function (e) {
  e.preventDefault();
  e.stopPropagation();
  $("#js-footer-text").addClass("hidden");
});
$('[data-login="sso"]').click(() => app.loginPopup.open());
