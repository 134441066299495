import $ from "jquery";
import throttle from "lodash/throttle";
import CustomProperty from "./CustomProperty";
import MainMenu from "./MainMenu";
import SSOLogin from "./SSOLogin";

/**
 * The main application file. You can import it as many times as you want,
 * and you will get the same instance
 * @class App
 */
class App {
  #customProperties = {};
  /**
   * @private
   * @type {Object}
   */
  #resizeHandlers = {};

  /**
   * @private
   * @type {{key: string, callback: Promise}[]}
   */
  #postLoginHandlers = [];

  /**
   * @public
   * @type {MainMenu}
   */
  mainMenu;

  /**
   * @public
   * @type {SSOLogin}
   */
  loginPopup;

  constructor() {
    this.mainMenu = new MainMenu();
    this.loginPopup = new SSOLogin(sso_user_helper.origin, sso_user_helper.iframe_url);
    this.loginPopup.addEventListener("success", async () => {
      this.loginPopup.close(false);
      for (const entry of this.#postLoginHandlers) {
        await entry.callback.call(this);
      }
    });
    this.subscribePostLogin("app-post-login", async () => {
      window.location.reload();
    });

    this.#setCustomProperties();
    window.addEventListener(
      "resize",
      throttle(() => {
        Object.values(this.#resizeHandlers).map((fn) => fn.call(this));
      }, 250),
    );
    setViewportProperty();
  }
  #setCustomProperties() {
    this.subscribeResize("vh", setViewportProperty);

    // screen-height
    this.addCustomProperty("screen-height", innerHeight + "px");
    this.subscribeResize("screen-height", () => {
      this.#customProperties["screen-height"].value = innerHeight + "px";
    });

    // header-height
    this.addCustomProperty("header-height", $(".site-header").outerHeight() + "px");
    this.subscribeResize("header-height", () => {
      this.#customProperties["header-height"].value = $(".site-header").outerHeight() + "px";
    });
  }

  /**
   * Creates CustomProperty and keeps it in App instance
   * @param {string} name The CSS property name
   * @param {string|number} value The CSS property value
   * @param {HTMLElement} [ctx=document.documentElement] The DOM Element style with apply to
   * @returns this
   * @example
   * import app from 'App';
   * app.addCustomProperty('hello', 'world');
   */
  addCustomProperty(name, value, ctx = document.documentElement) {
    this.#customProperties[name] = new CustomProperty(name, value, ctx);
    return this;
  }

  /**
   * Get CSS property from App instance
   * @param {string} name The CSS property name
   * @returns {any}
   * @example
   * import app from 'App';
   * app.getCustomProperty('hello');
   */
  getCustomProperty(name) {
    return this.#customProperties[name].value;
  }
  /**
   * Add handler to post login event
   * @param {string} name The handler identifier
   * @param {Promise} handler The callback to run on post-login
   * @param {string} [position='last'] The position of the handler in the handler array (first|last|key-of-handler)
   * @returns this
   * @example
   * import app from 'App';
   * app.subscribePostLogin('my-post-login-handler', () => {console.log('I'm listening to log in')});
   */
  subscribePostLogin(name, handler, position = "last") {
    let index = null;

    if (!["first", "last"].includes(position)) {
      index = this.#postLoginHandlers.findIndex((handler) => handler.key === name);
      index = index === -1 ? null : index;
    }

    if (position === "first") {
      this.#postLoginHandlers.unshift({ key: name, callback: handler });
    } else if (index !== null) {
      this.#postLoginHandlers.splice(index, 0, { key: name, callback: handler });
    } else {
      this.#postLoginHandlers.push({ key: name, callback: handler });
    }
    return this;
  }

  /**
   * Removes handler from the post login event
   * @param {string} name The handler identifier
   * @returns this
   * @example
   * import app from 'App';
   * app.unsubscribeResize('my-post-login-handler');
   */
  unsubscribePostLogin(name) {
    this.#postLoginHandlers = this.#postLoginHandlers.filter((handler) => handler.key !== name);
    return this;
  }

  /**
   * Add handler to window resize event
   * @param {string} name The handler identifier
   * @param {function} handler The callback to run on window resize
   * @returns this
   * @example
   * import app from 'App';
   * app.subscribeResize('my-resize-handler', () => {console.log('I'm listening to resize')});
   */
  subscribeResize(name, handler) {
    this.#resizeHandlers[name] = handler;
    return this;
  }
  /**
   * Removes handler from window resize event
   * @param {string} name The handler identifier
   * @returns this
   * @example
   * import app from 'App';
   * app.unsubscribeResize('my-resize-handler');
   */
  unsubscribeResize(name) {
    delete this.#resizeHandlers[name];
    return this;
  }
}

function setViewportProperty() {
  let prevClientHeight;
  function handleResize() {
    const clientHeight = document.documentElement.clientHeight;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(function updateViewportHeight() {
      document.documentElement.style.setProperty("--vh", clientHeight * 0.01 + "px");
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}

window.app = window.app || new App();
const app = window.app;
export default app;
