import $ from "jquery";

export function HeaderAccount() {
  $(".js-profile-toggle").on("click", function () {
    $(".js-profile-content, .js-profile-underlay").toggleClass("show");
  });
}

export function HeaderSearch() {
  $(".js-search-toggle").on("click", function () {
    $(".js-search-content, .js-search-underlay").toggleClass("show");
  });
}

export function HeaderStickyScrollUp() {
  if (screen.width < 992) {
    let lastScrollTop = 0;
    const header = $(".site-header");
    const body = $("body");

    $(window).scroll(function (event) {
      let st = $(this).scrollTop();

      if (st < 10) {
        header.addClass("header-show").removeClass("header-hide header-scrolled");
        return;
      }

      header.addClass("header-scrolled");

      if (st > lastScrollTop) {
        // Scroll Down
        header.addClass("header-hide").removeClass("header-show");
      } else {
        // Scroll Up
        header.removeClass("header-hide").addClass("header-show");
      }
      lastScrollTop = st;
    });
  }
}

$(".js-toggle-menu").click(() => {
  $("body").toggleClass("menu-open");
});

$(".has-submenu").hover(
  function () {
    $(this).siblings(".has-submenu").addClass("is-not-selected");
    $(this).addClass("is-hovered");
  },
  function () {
    $(this).removeClass("is-hovered");
    $(this).siblings(".has-submenu").removeClass("is-not-selected");
  },
);
