/**
 *
 * @class MainMenu
 */
class MainMenu {
  constructor() {}

  /**
   * Opens the website main menu
   */
  open() {}
  /**
   * Closes the website main menu
   */
  close() {}
}
export default MainMenu;
