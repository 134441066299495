import close from "../../svg/close-icon.svg?inline";

const template = `
  <div class="sso__underlay"></div>
  <div class="sso__container">
    <div class="sso__close">${close}</div>
    <div class="sso__iframe-container"></div>
  </div>
`;
export default class SSOLogin extends EventTarget {
  #iframe_url;
  #sso_origin;
  #div;
  #iframe_container;
  #iframe;

  constructor(sso_origin, iframe_url) {
    super();

    this.#sso_origin = sso_origin;
    this.#iframe_url = iframe_url;
    this.#div = document.createElement("div");
    this.#div.className = "sso";
    this.#div.innerHTML = template;
    this.#iframe_container = this.#div.querySelector(".sso__iframe-container");

    this._handleMessage = this._handleMessage.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.#iframe_container.classList.add("sso__iframe-container--loading");
    document.body.appendChild(this.#div);

    if (!this.#iframe) {
      this.#iframe = document.createElement("iframe");
      this.#iframe.src = this.#iframe_url;
      this.#iframe.onload = () => {
        this.#iframe_container.classList.remove("sso__iframe-container--loading");
        this.#bindEvents();
      };
      this.#iframe_container.appendChild(this.#iframe);
    }
  }

  close(fireEvents = true) {
    this.#div.remove();
    if (fireEvents) {
      this.dispatchEvent(new CustomEvent("cancel"));
    }
    this.#unbindEvents();
  }

  #bindEvents() {
    window.addEventListener("message", this._handleMessage, false);
    this.#div.querySelector(".sso__close").addEventListener("click", this.close);
    this.#div.querySelector(".sso__underlay").addEventListener("click", this.close);
  }

  #unbindEvents() {
    window.removeEventListener("message", this._handleMessage, false);
    this.#div.querySelector(".sso__close").removeEventListener("click", this.close);
    this.#div.querySelector(".sso__underlay").removeEventListener("click", this.close);
  }

  _handleMessage(event) {
    if (
      event.origin === this.#sso_origin &&
      event.data === "closeDialog" &&
      event.source === this.#iframe.contentWindow
    ) {
      this.dispatchEvent(new CustomEvent("success", { detail: event }));
      this.#unbindEvents();
    }
  }
}
